import { useContext, useEffect, useState } from 'react';
import {Navbar} from './index';
import { Appstate } from '../App';
import { ethers } from 'ethers';
import { Button } from "@mui/material"
import { TailSpin } from "react-loader-spinner";
import { toast } from 'react-toastify';

const Wallet = () => {
  const useAppState = useContext(Appstate);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [data, setData] = useState({
    dividend: 0,
    direct: 0,
    layer: 0,
    royalty: 0,
    total: 0
  })
  const [admin, setAdmin] = useState({
    royalty: 0,
    withdrawable: 0
  })
  const [isAdmin, setisAdmin] = useState(false);

  const withdraw = async () => {
    setLoading(true);
    try {
        let tx = await useAppState.contract.withdraw(ethers.utils.parseEther(amount));
        await tx.wait();
        useAppState.setChange(useAppState.change + 1);
        toast.success("Sucessfully Withdrawn");
    } catch (error) {
        toast.error(error.reason)
    }
    setLoading(false);
  }

  // const changePercent = async () => {
  //   setLoading2(true);
  //   try {
  //       let tx = await useAppState.contract.checkRoyalty(Number(percent) * 100, "0x0000000000000000000000000000000000000000", 0, 1);
  //       await tx.wait();
  //       useAppState.setChange(useAppState.change + 1);
  //       toast.success("Sucessfully Updated");
  //   } catch (error) {
  //       toast.error(error.reason)
  //   }
  //   setLoading2(false);
  // }

  useEffect(() => {
    async function req() {
      if(useAppState.walletAddress.toLowerCase() === ("0x996fb9496168f25d8242ad91d673e95e4b318b7c").toLowerCase()) {
        setisAdmin(true);
      }

      let _user = await useAppState.contract.rewardInfo(useAppState.walletAddress);
      const convert = (amount) => ethers.utils.formatEther(amount.toString());
      setData({
        dividend: convert(_user.dividendIncome),
        direct: convert(_user.directIncome),
        layer: convert(_user.layerIncome),
        royalty: convert(_user.royaltyIncome),
      })
      
      let _royal = await useAppState.contract.royalty();
      let _draw = await useAppState.contract.totalWithdrawable();
      setAdmin({
        royalty: convert(_royal),
        withdrawable: convert(_draw),
      })
    }
    req();
  },[useAppState.change, useAppState.walletAddress])

  return (
    <div className='w-full flex justify-center'>
      <img src='register.png' className='fixed w-full hidden md:block left-0 md:top-0 -z-10 top-0 opacity-70' />
      <img src='mainmob.png' className='fixed w-full block md:hidden left-0 md:top-0 -z-10 top-0 opacity-70' />

      <div className='px-2 py-4 w-full md:w-3/4'>
        <Navbar />
        <div className='mt-6'>
        <div className="w-full mt-4 p-3 bg-gray-800 rounded-2xl bg-opacity-40 text-gray-200 shadow-2xl flex flex-col items-center justify-between">
        <div className=" w-full p-2 rounded-md">
        <div className="flex w-full justify-between font-medium text-gray-300">
          <h1 className="px-2">Dividend Income</h1>
          <h1 className="ml-2 px-2">${data.dividend}</h1>
        </div>
        <div className="flex mt-2 w-full justify-between font-medium text-gray-300">
          <h1 className="px-2">Direct Income</h1>
          <h1 className="ml-2 px-2">${data.direct}</h1>
        </div>
        <div className="flex mt-2 w-full justify-between font-medium text-gray-300">
          <h1 className="px-2">Layer Income</h1>
          <h1 className="ml-2 px-2">${data.layer}</h1>
        </div>
        <div className="flex mt-2 w-full justify-between font-medium text-gray-300">
          <h1 className="px-2">Royalty Income</h1>
          <h1 className="ml-2 px-2">${data.royalty}</h1>
        </div>
        <div className="border-gray-500 border w-full mb-1 mt-3"></div>
        <div className="flex w-full justify-between mb-2 font-medium text-gray-300">
          <h1 className="px-2">Total Withdrawable</h1>
          <h1 className="ml-2 px-2">${Number(Number(data.dividend) + Number(data.direct) + Number(data.layer) + Number(data.royalty)).toFixed(2)}</h1>
        </div>

        <Button onClick={withdraw} className='w-full capitalize mt-3' variant="contained" color="success">
          {loading ? <TailSpin height={22} color="white" /> : 'Withdraw'}
        </Button>

        {isAdmin ? 
        <>
          <input
            type="text"
            id="full-name"
            name="full-name"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder='Enter Amount'
            className="w-full mt-3 bg-black bg-opacity-40 mb-3 shadow-lg rounded border-b border-gray-500 text-base outline-none text-white py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          />
          <Button onClick={withdraw} className='w-full capitalize mt-3' variant="contained" color="success">
            {loading ? <TailSpin height={22} color="white" /> : 'Withdraw'}
          </Button>
          <p className='mt-2 font-bold text-lg'>Royalty : {admin.royalty}</p>
          <p className='mt-2 font-bold text-lg'>Withdrawable : {admin.withdrawable}</p>
        </> 
        : null }
        </div>
      </div>
      </div>

      </div>
    </div>
  )
}

export default Wallet
