import { useContext, useEffect, useState, useRef } from 'react';
import {Navbar, Deposit, Claim, Pool} from './index';
import { Appstate } from '../App';
import CountUp from 'react-countup';
import { ethers } from 'ethers';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { ContentCopy, AdminPanelSettings } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CircleIcon from '@mui/icons-material/Circle';
import PaidIcon from '@mui/icons-material/Paid';
import PixIcon from '@mui/icons-material/Pix';
import { useTimer } from 'react-timer-hook';
import moment from 'moment';
import { TailSpin } from 'react-loader-spinner';
import { message } from 'antd';

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days
  } = useTimer({ expiryTimestamp});

  return <p><span>{days.toString().padStart(2, '0')}</span> days, <span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
}

const Dashboard = () => {
  const useAppState = useContext(Appstate);
  const [user, setUser] = useState({
    package: 0,
    revenue: 0,
    teams: 0,
    totalDeposit: 0,
    curRev: 0,
    referrer: "",
    start: 0,
    balance: 0,
    isRoyalty: false,
    is3x: false
  });
  const intervalRef = useRef(null);
  const [time, setTime] = useState(0);
  const [layers, setLayers] = useState([false, false, false, false, false, false, false, false, false, false,]);
  const [roaylty, setRoyalty] = useState(0);
  const [x3, setX3] = useState(0);
  const [royaltyTime, setRoyaltyTime] = useState(0);
  const [x3Time, setX3Time] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if(Number(user.start) > 0) {
        setTime(Date.now() - Number(user.start) * 1000);
      } else {
        setTime(0);
      }
    }, 10);
    return () => clearInterval(intervalRef.current);
  }, [user.start]);

  const formatTime = (time) => {
    const sec = Math.floor((time / 1000) % 60);
    const min = Math.floor((time / 60000) % 60);
    const hr = Math.floor((time / 3600000) % 24);
    const days = Math.floor(time / 86400000);
    if(days <= 0) {
      return (
        `${hr.toString().padStart(2, '0')} : ` +
        `${min.toString().padStart(2, '0')} : ` +
        `${sec.toString().padStart(2, '0')}`
      );
    } else {
      return `${days.toString().padStart(2, '0')} Days`
    }
  };

  useEffect(() => {
    async function req() {
      let _user = await useAppState.contract.userInfo(useAppState.walletAddress);
      let _bal = await useAppState.tokenContract.balanceOf(useAppState.walletAddress)
      setUser({
        referrer: _user.referrer,
        package: ethers.utils.formatEther(_user.package),
        revenue: ethers.utils.formatEther(_user.revenue),
        curRev: ethers.utils.formatEther(_user.curRevenue),
        totalDeposit: ethers.utils.formatEther(_user.totalDeposit),
        teams: Number(_user.totalTeam),
        start: Number(_user.start),
        balance: ethers.utils.formatEther(_bal),
        isRoyalty: _user.isRoyalty,
        is3x: _user.is3x
      })

      const _layers = await useAppState.contract.getClaimableLayers(useAppState.walletAddress);
      setLayers(_layers);
      
      let _business = await useAppState.contract.getRoyaltyVolume(useAppState.walletAddress);
      let _percent = (Number(ethers.utils.formatEther(_business)) * 100)/50000;
      if(_percent > 100) _percent = 100;
      setRoyalty(_percent)

      let _curTime = await useAppState.contract.getUserCurDay(useAppState.walletAddress);
      let _direct = await useAppState.contract.direct3x(useAppState.walletAddress, parseInt(Number(_curTime)/30));
      setX3(Number(_direct));

      let _royalTime = await useAppState.contract.getRoyaltyCountdown(useAppState.walletAddress);
      let _x3Time = await useAppState.contract.get3xCountdown(useAppState.walletAddress);
      setRoyaltyTime(Number(_royalTime * 1000));
      setX3Time(Number(_x3Time * 1000));
      setShow(true);
    }
    req();
  },[useAppState.walletAddress, useAppState.change])

  return (
    <div className='w-full flex justify-center'>
      <img src='register.png' className='fixed w-full hidden md:block left-0 md:top-0 -z-10 top-0 opacity-70' />
      <img src='mainmob.png' className='fixed w-full block md:hidden left-0 md:top-0 -z-10 top-0 opacity-70' />

      <div className='px-2 py-4 w-full md:w-3/4'>
        <Navbar />

        <Link to={'/'}><div className='bg-purple-600 bg-opacity-10 font-semibold border-yellow-400 border-b font-poppins flex justify-center text-yellow-400 mt-4 rounded-full p-2'>
            <h4 className="font-poppins font-semibold text-[15px] text-white">
              Learn About BGN
            </h4>
            <ArrowOutwardIcon color='inherit' fontSize='medium' className='ml-2' />
        </div></Link>

        <div className="w-full font-medium text-gray-400 border-opacity-25 mt-4 p-1 bg-gray-800 rounded-full bg-opacity-40 shadow-2xl flex items-center justify-between">
          <p className='bg-gray-950 rounded-full bg-opacity-40 py-2 px-2'><AdminPanelSettings fontSize='medium' color='success' /> {useAppState.walletAddress.slice(0, 6) + "..." + useAppState.walletAddress.slice(38)} <span className='h-full px-2 py-1 bg-gray-900 rounded-full'>${parseInt(user.balance)}</span></p>
          <div className="text-lg px-2">{formatTime(time)}</div>
        </div>
        <Claim />
        <div className='flex mt-6 mb-2'>
          <p className='text-xl md:text-3xl rounded-lg flex flex-col items-center bg-gray-800 text-center py-4 bg-opacity-30 shadow-xl font-bold h-full w-1/3'>
            <img src='package.png' className='h-16 md:h-20' />
            <p className='text-lg'>Package</p>
            <p className='flex'>$<CountUp end={Number(user.package)} duration={3} /></p>
          </p>
          <p className='text-xl md:text-3xl rounded-lg ml-3 flex flex-col items-center bg-gray-800 text-center py-4 bg-opacity-30 shadow-xl font-bold h-full w-1/3'>
            <img src='revenue.png' className='h-16 md:h-20' />
            <p className='text-lg'>Revenue</p>
            <p className='flex'>$<CountUp end={Number(user.curRev)} duration={3} decimals={2} /></p>
            {Number(user.curRev) >= (Number(user.package) * (user.is3x ? 3 : 2) * 75)/100 ? <p className='text-red-500 font-medium text-lg animate-pulse'>{user.is3x ? 3 : 2}x on {Number(user.package) * 2}</p> : null}
          </p>
          <p className='text-xl md:text-3xl rounded-lg ml-3 flex flex-col items-center bg-gray-800 text-center py-4 bg-opacity-30 shadow-xl font-bold h-full w-1/3'>
            <img src='teams.png' className='h-16 md:h-20' />
            <p className='text-lg'>Teams</p>
            <p className='flex'><CountUp end={Number(user.teams)} duration={3} /></p>
          </p>
        </div>

        <div className='flex md:flex-row flex-col mt-6 mb-2'>
          <p className='text-xl md:text-3xl rounded-lg flex items-center bg-gray-800 text-center py-2 bg-opacity-30 shadow-xl font-bold h-full w-full md:w-1/2'>
            <p className='text-5xl ml-3'><PaidIcon fontSize='inherit' color='primary' /></p>
            <div className='flex flex-col ml-3 items-start'>
              <p className='text-lg'>Total Deposit</p>
              <p className='flex'>$<CountUp end={Number(user.totalDeposit)} duration={3} /></p>
            </div>
          </p>
          <p className='text-xl md:ml-3 md:mt-0 mt-3 ml-0 md:text-3xl rounded-lg flex items-center bg-gray-800 text-center py-2 bg-opacity-30 shadow-xl font-bold h-full w-full md:w-1/2'>
            <p className='text-5xl ml-3'><PixIcon fontSize='inherit' color='primary' /></p>
            <div className='flex flex-col ml-3 items-start'>
              <p className='text-lg'>Total Revenue</p>
              <p className='flex'>$<CountUp end={Number(user.revenue)} duration={3} decimals={2} /></p>
            </div>
          </p>
        </div>

        {/* Upgrade Package */}
        <Deposit />

        <div className='mt-6 p-3 bg-gray-800 overflow-x-auto rounded-2xl bg-opacity-40 whitespace-nowrap font-medium text-gray-400 shadow-2xl relative'>
          <h1><span className='text-lime-600'>Referral Link:</span> bgnnet.online/register?ref={useAppState.walletAddress}
            <CopyToClipboard text={`https://bgnnet.online/register?ref=${useAppState.walletAddress}`} >
              <ContentCopy onClick={() => toast.success("Copied")} fontSize="small" color="primary" className="ml-2 mr-2 cursor-pointer" />
            </CopyToClipboard>
          </h1>
        </div>

        <div className='mt-4 p-3 bg-gray-800 overflow-x-auto rounded-2xl bg-opacity-40 whitespace-nowrap font-medium text-gray-400 shadow-2xl relative'>
          <p><span className='text-lime-600'>Referrer:</span> {user.referrer} <span className='mr-2'></span></p>
        </div>

        <div className='mt-4 p-3 bg-gray-800 flex overflow-x-auto rounded-2xl bg-opacity-40 whitespace-nowrap text-gray-400 shadow-2xl relative'>
          <p className='flex items-center'><span className='text-white'>Layer 1: <CircleIcon fontSize='small' color={layers[0] ? 'success' : 'error'} /></span></p>
          <p className='flex ml-6 items-center'><span className='text-white'>Layer 2: <CircleIcon fontSize='small' color={layers[1] ? 'success' : 'error'} /></span></p>
          <p className='flex ml-6 items-center'><span className='text-white'>Layer 3: <CircleIcon fontSize='small' color={layers[2] ? 'success' : 'error'} /></span></p>
          <p className='flex ml-6 items-center'><span className='text-white'>Layer 4: <CircleIcon fontSize='small' color={layers[3] ? 'success' : 'error'} /></span></p>
          <p className='flex ml-6 items-center'><span className='text-white'>Layer 5: <CircleIcon fontSize='small' color={layers[4] ? 'success' : 'error'} /></span></p>
          <p className='flex ml-6 items-center'><span className='text-white'>Layer 6: <CircleIcon fontSize='small' color={layers[5] ? 'success' : 'error'} /></span></p>
          <p className='flex ml-6 items-center'><span className='text-white'>Layer 7: <CircleIcon fontSize='small' color={layers[6] ? 'success' : 'error'} /></span></p>
          <p className='flex ml-6 items-center'><span className='text-white'>Layer 8: <CircleIcon fontSize='small' color={layers[7] ? 'success' : 'error'} /></span></p>
          <p className='flex ml-6 items-center'><span className='text-white'>Layer 9: <CircleIcon fontSize='small' color={layers[8] ? 'success' : 'error'} /></span></p>
          <p className='flex ml-6 items-center'><span className='text-white'>Layer 10: <CircleIcon fontSize='small' color={layers[9] ? 'success' : 'error'} /></span></p>
        </div>

      <div className='flex justify-between'>
        <div className='w-1/2 mt-4 p-3 flex flex-col items-center bg-gray-800 overflow-x-auto rounded-2xl bg-opacity-40 whitespace-nowrap font-medium text-gray-400 shadow-2xl relative'>
          <img src='royalty.png' className='h-32' />
          <div className='flex flex-col items-center text-xl'>
            <h1 className='text-gradient text-2xl'>Roaylty</h1>
            {show && !(user.isRoyalty) ? <MyTimer expiryTimestamp={royaltyTime} /> : null }
            <h1 className='text-xl'>{user.isRoyalty ? <span className='text-lime-500 animate-pulse'>Active</span> : <span>{roaylty}%</span>}</h1>
          </div>
        </div>

        <div className='w-1/2 ml-4 mt-4 p-3 flex flex-col items-center bg-gray-800 overflow-x-auto rounded-2xl bg-opacity-40 whitespace-nowrap font-medium text-gray-400 shadow-2xl relative'>
          <img src='x3.png' className='h-32' />
          <div className='flex flex-col items-center'>
            <h1 className='text-gradient text-2xl'>3x Income Cap</h1>
            {show && !(user.is3x) ? <MyTimer expiryTimestamp={x3Time} /> : null }
            <h1 className='text-xl'>{user.is3x ? <span className='text-lime-500 animate-pulse'>Active</span> : <span>{x3}/5</span>}</h1>
          </div>
        </div>
      </div>

        {/* Pool timer */}
        <Pool />
      </div>
    </div>
  )
}

export default Dashboard