import { useContext, useEffect, useState, useRef } from "react";
import { Appstate } from "../App";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import moment from "moment";
import { ethers } from "ethers";

const Claim = () => {
    const useAppState = useContext(Appstate);
    const [loading, setLoading] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(0);
    const intervalRef = useRef(null);
    const [time, setTime] = useState(0);
    const [amount, setAmount] = useState(0);

    useEffect(() => {
      intervalRef.current = setInterval(() => {
        if(timeRemaining > 0) {
          setTime(Date.now() - timeRemaining);
        } else {
          setTime(0);
        }
      }, 10);
      return () => clearInterval(intervalRef.current);
    }, [timeRemaining]);

    const formatTime = (time) => {
      const sec = Math.floor((time / 1000) % 60);
      const min = Math.floor((time / 60000) % 60);
      const hr = Math.floor((time / 3600000) % 24);
      const days = Math.floor(time / 86400000);
      return (
        `${days.toString().padStart(2, '0')} Days, ` +
        `${hr.toString().padStart(2, '0')} : ` +
        `${min.toString().padStart(2, '0')} : ` +
        `${sec.toString().padStart(2, '0')}`
      );
    };
  

    const claim = async () => {
        setLoading(true);
        try {
            let tx = await useAppState.contract.claim();
            await tx.wait();
            useAppState.setChange(useAppState.change + 1);
            toast.success("Sucessfully Claimed");
        } catch (error) {
            toast.error(error.reason)
        }
        setLoading(false);
    }

    useEffect(() => {
      async function req() {
        let _user = await useAppState.contract.userInfo(useAppState.walletAddress);
        const final = Number(_user.lastClaimed) * 1000;
        setTimeRemaining(final);

        let _amt = await useAppState.contract.getClaimableDividend(useAppState.walletAddress);
        setAmount(ethers.utils.formatEther(_amt));
      }
      req();
    },[useAppState.walletAddress, useAppState.change])

  return (
    <div className="mt-4 blue__gradient py-3 rounded-lg flex justify-between items-center px-4">
        <div className="text-xl font-medium">{formatTime(time)}</div>
        <button onClick={claim} className="px-4 py-2 font-medium bg-lime-600 flex justify-center rounded-xl">{loading ? <TailSpin height={20} color="white" /> : <span>Claim ${Number(amount).toFixed(2)}</span>}</button>
    </div>
  )
}

export default Claim