import { Link, useLocation } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupsIcon from '@mui/icons-material/Groups';
import WalletIcon from '@mui/icons-material/Wallet';

const Navbar = () => {
  const location = useLocation();

  return (
    <div className='w-full rounded-full flex justify-between shadow-xl bg-gray-800 bg-opacity-30 font-medium'>
      <Link className="w-full" to={'/dashboard'}><p className={`${location.pathname == '/dashboard' ? 'bg-blue-900 bg-opacity-25' : ''} w-full md:py-3 py-4 text-center transition-colors duration-300 hover:bg-gray-900 ease-in rounded-full`}><DashboardIcon fontSize="small" /> Dashboard</p></Link>
      <Link className="w-full" to={'/teams'}><p className={`${location.pathname == '/teams' ? 'bg-blue-900 bg-opacity-25' : ''} w-full md:py-3 py-4 text-center transition-colors duration-300 hover:bg-gray-900 ease-in rounded-full`}><GroupsIcon fontSize="small" /> Teams</p></Link>
      <Link className="w-full" to={'/wallet'}><p className={`${location.pathname == '/wallet' ? 'bg-blue-900 bg-opacity-25' : ''} w-full md:py-3 py-4 text-center transition-colors duration-300 hover:bg-gray-900 ease-in rounded-full`}><WalletIcon fontSize="small" /> Wallet</p></Link>
    </div>
  )
}

export default Navbar