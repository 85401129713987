import { Popover } from "antd";
import { useContext, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { Appstate } from "../App";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import moment from "moment";

const Deposit = () => {
    const useAppState = useContext(Appstate)
    const [open, setOpen] = useState(false);
    const [select, setSelect] = useState([100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2300, 2400, 2500]);
    const [amount, setAmount] = useState(0);
    const [loading, setLoading] = useState({
      0: false,
      1: false
    });

    const upgrade = async (_type) => {
        setLoading({...loading, [_type]: true});
        try {
            let _amt = await useAppState.contract.getClaimableDividend(useAppState.walletAddress);
            if(Number(_amt) > 0) {
              toast.warn("Claim Dividend First");
              setLoading({...loading, [_type]: false});
              return;
            }

            let allowance = await useAppState.tokenContract.allowance(useAppState.walletAddress, useAppState.mainAddr);

            if(Number(ethers.utils.formatEther(allowance)) < Number(amount)) {
              const approval = await useAppState.tokenContract.approve(useAppState.mainAddr, ethers.utils.parseEther('2500'));
              await approval.wait();
              toast.success("Approved");
            } 

            let tx = await useAppState.contract.buyPackage(ethers.utils.parseEther(amount.toString()), _type);
            await tx.wait();

            useAppState.setChange(useAppState.change + 1);
            setAmount(0);
            toast.success(`Sucessfully Deposited`);
        } catch (error) {
            toast.error(error.reason)
        }
        setLoading({...loading, [_type]: false});
    }

    const settings = (
        <>
          <div className='overflow-y-auto h-56 w-full'>
            {select.map((e, i) => {
               return <h1 key={i} onClick={() => {setAmount(e); setOpen(false)}} className="flex mt-2 items-center justify-center cursor-pointer hover:bg-blue-600 hover:bg-opacity-30 bg-blue-500 bg-opacity-20 rounded-lg text-center w-full px-20 py-2 text-md font-bold">${e} <img src="usdt.png" className="h-4 ml-1" /></h1>
            })
            }
          </div>
        </>
    );

  return (
    <div className="mt-5">
        <Popover
            content={settings}
            title=""
            trigger="click"
            placement="bottom"
            className='bg-black-gradient py-2 mb-4 w-full bg-opacity-80 rounded-full cursor-pointer shadow-lg flex justify-center  items-center'
            open={open}
          >
          <div onClick={() => setOpen(open ? false : true)} className="headerItem">
              <h1 className="flex justify-center text-xl items-center font-medium">{amount > 0 ? <p className="flex items-center">${amount} <img src="usdt.png" className="h-6 ml-1 mt-1" /></p> : <p className="flex items-center">Select Amount <img src="usdt.png" className="h-6 ml-1 mt-1" /></p>}</h1>
          </div>
        </Popover>
  
        <button onClick={() => upgrade(0)} className="button-82-pushable w-full" role="button">
            <span className="button-82-shadow"></span>
            <span className="button-82-edge"></span>
            <span className="button-82-front text font-medium font-mono">
              {loading[0] ? <p className='flex justify-center'><TailSpin color='white' height={29} /></p> : 'Upgrade'}
            </span>
        </button>
        <button onClick={() => upgrade(1)} className="button-83-pushable mt-4 w-full" role="button">
            <span className="button-83-shadow"></span>
            <span className="button-83-edge"></span>
            <span className="button-83-front text font-medium font-mono">
              {loading[1] ? <p className='flex justify-center'><TailSpin color='white' height={29} /></p> : 'Increase'}
            </span>
        </button>
    </div>
  )
}

export default Deposit