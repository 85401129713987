import {Homepage, Dashboard, Register, Teams, Wallet} from "./components/index";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Aos from 'aos';
import "aos/dist/aos.css"
import { createContext, useEffect, useState } from "react";
import USDC from './usdt.json';
import Bitexy from './artifacts/contracts/BGN.sol/BGN.json' 
import { ethers } from "ethers";

const Appstate = createContext();

function App() {
  const {ethereum} = window;
  const [walletAddress, setWalletAddress] = useState("");
  const [change, setChange] = useState(0);
  const usdt = "0x55d398326f99059fF775485246999027B3197955";
  // const mainAddr = "0x45C78A83F113Ce675CEd206A884624D056294D5fX";
  const mainAddr = "0xdf003c8DA2b8Ba67BCfB8605e395042C2a987826";
  const defaultRefer = "0xdf003c8DA2b8Ba67BCfB8605e395042C2a987826"
  let provider;
  if(ethereum !== undefined) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
  } else {
    provider = new ethers.providers.JsonRpcProvider();
  }

  const signer = provider.getSigner();
  const contract = new ethers.Contract(mainAddr, Bitexy.abi, signer);
  const tokenContract = new ethers.Contract(usdt, USDC.output.abi, signer);

  useEffect(() => {
    async function req() {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setWalletAddress(accounts[0]);
      // setWalletAddress("0xdf003c8DA2b8Ba67BCfB8605e395042C2a987826");
    }
    req();
  }, []);

  if (window.ethereum !== undefined) {
    ethereum.on("accountsChanged", (accounts) => {
      setWalletAddress(accounts[0]);
    });
  }

  useEffect(() => {
    Aos.init();
  },[]) 

  return (
    <Appstate.Provider value={{usdt, mainAddr, contract, tokenContract, walletAddress, setChange, change, defaultRefer}}>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/register" element={<Register />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Appstate.Provider>
  );
}

export default App;
export {Appstate}
