import { useContext, useEffect, useState } from 'react';
import {Navbar} from './index';
import { Appstate } from '../App';
import { ThreeDots } from 'react-loader-spinner';
import { ethers } from 'ethers';
import CountUp from 'react-countup';

const Teams = () => {
  const useAppState = useContext(Appstate);
  const [data, setData] = useState({
    totalTeam: 0,
    directTeam: 0,
    directBusiness: 0,
    strongLeg: 0,
    required: 0,
    total: 0
  })
  const [layer, setLayer] = useState(1);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([
    {
      address: '',
      start: 0,
      directTeam: 0,
      directBusiness: 0,
      totalDeposit: 0,
      package: 0
    },
  ]);

  useEffect(() => {
    async function req() {
      let _user = await useAppState.contract.userInfo(useAppState.walletAddress);
      let _business = await useAppState.contract.getBusinessVolume(useAppState.walletAddress, ethers.utils.parseEther("50000"));
      const convert = (amount) => ethers.utils.formatEther(amount); 
      setData({
        totalTeam: Number(_user.totalTeam),
        directTeam: Number(_user.directTeam),
        directBusiness: convert(_user.directBusiness),
        total: convert(_business[0]),
        strongLeg: convert(_business[2]),
      })
    }
    req();
  },[useAppState.walletAddress])

  useEffect(() => {
    async function req() {
      setLoading(true);
      let length = await useAppState.contract.getTeamsLength(useAppState.walletAddress, Number(layer) - 1);
      let arr = [];
      for(let i=0; i<Number(length); i++) {
        let curUser = await useAppState.contract.teamUsers(useAppState.walletAddress, Number(layer) - 1, i);
        let curUserInfo = await useAppState.contract.userInfo(curUser);
        arr.push({
          address: curUser,
          start: Number(curUserInfo.start),
          directTeam: Number(curUserInfo.directTeam),
          directBusiness: ethers.utils.formatEther(curUserInfo.directBusiness),
          package: ethers.utils.formatEther(curUserInfo.package),
          totalDeposit: ethers.utils.formatEther(curUserInfo.totalDeposit)
        })
      }
      setUsers(arr);
      setLoading(false);
    }
    req();
  },[useAppState.walletAddress, layer])

  return (
    <div className='w-full flex justify-center'>
      <img src='register.png' className='fixed w-full hidden md:block left-0 md:top-0 -z-10 top-0 opacity-70' />
      <img src='mainmob.png' className='fixed w-full block md:hidden left-0 md:top-0 -z-10 top-0 opacity-70' />

      <div className='px-2 py-4 w-full md:w-3/4'>
        <Navbar />
        <div className="w-full mt-4 p-3 bg-gray-800 rounded-2xl bg-opacity-40 text-gray-200 shadow-2xl flex flex-col items-center justify-between">
        <div className=" w-full p-2 rounded-md">
        <div className="flex w-full justify-between font-medium text-gray-300">
          <h1 className="px-2">Total Team</h1>
          <h1 className="ml-2 px-2"><CountUp end={Number(data.totalTeam)} duration={3} /></h1>
        </div>
        <div className="flex mt-2 w-full justify-between font-medium text-gray-300">
          <h1 className="px-2">Direct Team</h1>
          <h1 className="ml-2 px-2"><CountUp end={Number(data.directTeam)} duration={3} /> </h1>
        </div>
        <div className="flex mt-2 w-full justify-between font-medium text-gray-300">
          <h1 className="px-2">Direct Business</h1>
          <h1 className="ml-2 px-2">$<CountUp end={Number(data.directBusiness)} duration={3} decimals={2} /> </h1>
        </div>
        <div className="flex mt-2 w-full justify-between font-medium text-gray-300">
          <h1 className="px-2">Strong Leg Business</h1>
          <h1 className="ml-2 px-2">$<CountUp end={Number(data.strongLeg)} duration={3} decimals={2} /></h1>
        </div>
        <div className="flex mt-2 w-full justify-between font-medium text-gray-300">
          <h1 className="px-2">Total Business</h1>
          <h1 className="ml-2 px-2">$<CountUp end={Number(data.total)} duration={3} decimals={2} /></h1>
        </div>
        </div>
        </div>
        {/* Teams */}
        <select
          onClick={(e) => setLayer(e.target.value)}
          className="p-2 outline-none shadow-xl rounded-full full__gradient bg-opacity-40 text-white mt-4 w-full"
        >
        <option value={"1"} className="bg-black bg-blend-multiply bg-opacity-80">Layer 1</option>
        <option value={"2"} className="bg-black bg-blend-multiply bg-opacity-80">Layer 2</option>
        <option value={"3"} className="bg-black bg-blend-multiply bg-opacity-80">Layer 3</option>
        <option value={"4"} className="bg-black bg-blend-multiply bg-opacity-80">Layer 4</option>
        <option value={"5"} className="bg-black bg-blend-multiply bg-opacity-80">Layer 5</option>
        <option value={"6"} className="bg-black bg-blend-multiply bg-opacity-80">Layer 6</option>
        <option value={"7"} className="bg-black bg-blend-multiply bg-opacity-80">Layer 7</option>
        <option value={"8"} className="bg-black bg-blend-multiply bg-opacity-80">Layer 8</option>
        <option value={"9"} className="bg-black bg-blend-multiply bg-opacity-80">Layer 9</option>
        <option value={"10"} className="bg-black bg-blend-multiply bg-opacity-80">Layer 10</option>
      </select>
      <div className="flex mt-4 flex-col items-center w-full">
        {loading ? (
          <ThreeDots color="white" height={30} />
        ) : (
          <div className="overflow-x-auto rounded-xl flex justify-between bg-gray-800 bg-opacity-40 shadow-lg text-white w-full p-2">
            <div className="whitespace-nowrap ml-4 md:ml-0">
              <p className="whitespace-nowrap  p-1 font-bold text-yellow-400">SNo.</p>
              {users.map((e, i) => {
                return <p key={i} className="whitespace-nowrap  p-1">{i + 1}</p>;
              })}
            </div>

            <div className="whitespace-nowrap ml-4 md:ml-0">
              <p className="whitespace-nowrap  p-1 font-bold text-yellow-400">Started</p>
              {users.map((e, i) => {
                return <p key={i} className="whitespace-nowrap  p-1">{new Date(Number(e.start * 1000)).toLocaleString()}</p>;
              })}
            </div>

            <div className="whitespace-nowrap ml-4 md:ml-0">
              <p className="whitespace-nowrap  p-1 font-bold text-yellow-400">Address</p>
              {users.map((e, i) => {
                return <p key={i} className="whitespace-nowrap  p-1">{e.address.slice(0,6)}...{e.address.slice(38)}</p>;
              })}
            </div>

            <div className="whitespace-nowrap ml-4 md:ml-0">
              <p className="whitespace-nowrap  p-1 font-bold text-yellow-400">Package</p>
              {users.map((e, i) => {
                return <p key={i} className="whitespace-nowrap  p-1">{e.package}</p>;
              })}
            </div>

            <div className="whitespace-nowrap ml-4 md:ml-0">
              <p className="whitespace-nowrap  p-1 font-bold text-yellow-400">Total Deposit</p>
              {users.map((e, i) => {
                return <p key={i} className="whitespace-nowrap  p-1">{e.totalDeposit}</p>;
              })}
            </div>

            <div className="whitespace-nowrap ml-4 md:ml-0">
              <p className="whitespace-nowrap  p-1 font-bold text-yellow-400">Direct Team</p>
              {users.map((e, i) => {
                return <p key={i} className="whitespace-nowrap  p-1">
                    {e.directTeam}
                </p>;
              })}
            </div>

            <div className="whitespace-nowrap ml-4 md:ml-0">
              <p className="whitespace-nowrap  p-1 font-bold text-yellow-400">Direct Business</p>
              {users.map((e, i) => {
                return <p key={i} className="whitespace-nowrap  p-1">
                    ${e.directBusiness}
                </p>;
              })}
            </div>
          </div>
        )}
      </div>
    </div>
    </div>
  )
}

export default Teams