import { useContext, useEffect, useState, useRef } from "react";
import { Appstate } from "../App";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useTimer } from 'react-timer-hook';
import moment from "moment/moment";

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
  } = useTimer({ expiryTimestamp});

  return <p className="text-lg font-medium"><span>{hours.toString().padStart(2, '0')}</span> : <span>{minutes.toString().padStart(2, '0')}</span> : <span>{seconds.toString().padStart(2, '0')}</span></p>
}

const Pool = () => {
    const useAppState = useContext(Appstate);
    const [loading, setLoading] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(moment().add(24, "hours").valueOf());
    const [show, setShow] = useState(false);

    const claim = async () => {
        setLoading(true);
        try {
            let tx = await useAppState.contract.distributeRoyalty();
            await tx.wait();
            useAppState.setChange(useAppState.change + 1);
            toast.success("Sucessfully Distributed");
        } catch (error) {
            toast.error(error.reason)
        }
        setLoading(false);
    }

    useEffect(() => {
      async function req() {
        let _user = await useAppState.contract.royaltyLastDistributed();
        const final = moment().valueOf() - (Number(_user) * 1000);
        setTimeRemaining(moment().add(24, "hours").valueOf() - final);
        setShow(true);
      }
      req();
    },[useAppState.change])

  return (
    <div className="mt-4 green__gradient py-3 rounded-lg flex justify-between items-center px-4">
      <div className="">
        <p className="text-sm">Royalty Pool</p>
        {show ?
        <MyTimer expiryTimestamp={timeRemaining} />
        : null}
      </div>
        <button onClick={claim} className="px-4 py-2 font-medium bg-lime-600 flex justify-center rounded-xl">{loading ? <TailSpin height={20} color="white" /> : "Distribute"}</button>
    </div>
  )
}

export default Pool