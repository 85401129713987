import React, { useState, useEffect, useContext } from 'react'
import { useSearchParams, useLocation, Link, useNavigate } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { TailSpin } from 'react-loader-spinner';
import { Appstate } from '../App';
import { toast } from 'react-toastify';
import { arrayUnion, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from './firebase';

const Register = () => {
  const useAppState = useContext(Appstate);
  const {ethereum} = window;
  const router = useLocation();
  const Navigate = useNavigate();
  const [referrer, setReferrer] = useState("");
  const [address, setAddress] = useState("");
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const connectWallet = async () => {
    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });
    setAddress(accounts[0]);
  };

  useEffect(() => {
    async function getData() {
      let _user = await useAppState.contract.userInfo(useAppState.walletAddress);
      if(_user.referrer == "0x0000000000000000000000000000000000000000") {

      } else {
        Navigate('/dashboard')
      }
    }
    getData();
  },[])

  useEffect(() => {
    let ref = searchParams.get("ref");
    if(ref !== null) {
      setReferrer(ref)
    } else {
      // setReferrer(useAppState.defaultRefer)
    }
    connectWallet();
  }, [router]);

  const register = async () => {
    setLoading(true);
    try {
      let tx = await useAppState.contract.register(referrer);
      await tx.wait();

      await setDoc(doc(db, "users", (useAppState.walletAddress).toLowerCase()), {
        address: (useAppState.walletAddress).toLowerCase(),
        referrer: (referrer).toLowerCase(),
        totalBusiness: 0,
        selfDeposit: 0,
        directUsers: []
      })

      await updateDoc(doc(db, "users", (referrer).toLowerCase()), {
        directUsers: arrayUnion((useAppState.walletAddress).toLowerCase())
      })

      toast.success("Sucessfully Registered");
      Navigate('/dashboard');
    } catch (error) {
      toast.error(error.reason);
    }
    setLoading(false);
  }

  return (
    <div className='text-center p-4 w-full'>
      <img src='register.png' className='fixed w-full hidden md:block left-0 md:top-0 -z-10 top-0 opacity-70' />
      <img src='registermob.png' className='fixed w-full block md:hidden left-0 md:top-0 -z-10 top-0 opacity-70' />

      <p className="text-gradient text-4xl font-bold">
        Register
      </p>
      <div className='w-full flex md:flex-row flex-col items-center justify-center'>
       <div className='bg-gray-800 mt-12 h-[320px] flex flex-col items-center mr-0 md:mr-8 w-full md:w-1/3 rounded-lg bg-opacity-25 p-4'>
          <img src='logo.png' className='h-20' />
          <p className='bg-gray-900 flex items-center py-3 px-4 bg-opacity-70 mt-4 rounded-full' onClick={connectWallet}>
            <AdminPanelSettingsIcon fontSize='medium' color='success' />
            {address === ""
            ? "Connect Wallet"
            : address.slice(0, 6) + "..." + address.slice(38)}
          </p>
          <p className='break-all mt-4 font-medium'>Referrer: <span className='text-gray-300'>{referrer}</span></p>
          <button onClick={register} className="button-82-pushable w-full mt-4" role="button">
            <span className="button-82-shadow"></span>
            <span className="button-82-edge"></span>
            <span className="button-82-front text">
              {loading ? <p className='flex justify-center'><TailSpin color='white' height={22} /></p> : 'Register'}
            </span>
          </button>
       </div>
       <img src='mob.png' className='h-[410px] mt-8 ml-0 md:ml-40' />
      </div>
    </div>
  )
}

export default Register