import { Link, Navigate, useNavigate } from "react-router-dom";
import CountUp from 'react-countup'
import { useContext, useEffect, useState } from "react";
import { Appstate } from "../App";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import VerifiedIcon from '@mui/icons-material/Verified';
import BlockIcon from '@mui/icons-material/Block';
import PixIcon from '@mui/icons-material/Pix';
import LayersIcon from '@mui/icons-material/Layers';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { toast } from "react-toastify";

const Homepage = () => {
  const useAppState = useContext(Appstate);
  const navigate = useNavigate();
  const [data, setData] = useState({
    totalUsers: 0
  })

  const login = async () => {
    let _user = await useAppState.contract.userInfo(useAppState.walletAddress);
    let isAdmin = false;
    let arr = ["0x969a0Dd0E3e4C12Cd8b4059D36d76E2fCb318c88"]
    for(let i=0; i<arr.length; i++) {
      if((useAppState.walletAddress).toLowerCase() == (arr[i]).toLowerCase()) {
        isAdmin = true;
      }
    }
    if(_user.referrer == "0x0000000000000000000000000000000000000000") {
      if((useAppState.walletAddress).toLowerCase() == (useAppState.defaultRefer).toLowerCase() || isAdmin) {
        navigate('/dashboard')
      } else {
        toast.warn("Register First");
      }
    } else {
      navigate('/dashboard')
    }
  }

  useEffect(() => {
    async function req() {
      let total = await useAppState.contract.totalUsers();
      setData({
        totalUsers: Number(total) - 1
      })
    }
    req();
  },[])

  return (
  <div className="w-full px-3">
    <img src='register.png' className='fixed w-full hidden md:block left-0 md:top-0 -z-10 top-0 opacity-70' />
    <img src='registermob.png' className='fixed w-full block md:hidden left-0 md:top-0 -z-10 top-0 opacity-70' />

    <div className="w-full flex justify-between items-center py-3">
      <img src='logo.png' className='h-16' />
      <p onClick={login} className=" text-white font-medium bg-lime-500 px-6 py-2 rounded-lg">Login</p>
    </div>
    <div className="w-full md:px-4 flex flex-col-reverse md:flex-row justify-center items-center">
      <div className="md:w-1/2 w-full">
        <p className="text-gradient mt-6 text-6xl font-bold">Welcome</p>
        <p className="text-yellow-500 font-medium text-lg">To the world of Decentralization.</p>
        <p className="mb-2">
          BGN is an innovative crowdfunding platform that harnesses the
          power of smart contracts to create a decentralized ecosystem where
          individuals can contribute to community projects and businesses. The
          platform utilizes robot trading in both the forex and crypto markets to
          generate additional funds for the community, increasing the overall
          return oon investmentss.
        </p>
        <Link to={'/register'}><button className="button-5 mt-2 px-5 py-[11px] rounded-md" role="button">Register</button></Link>
      </div>
      <img src='hero.png' className='h-[380px] mt-8 ml-0 md:ml-40' />
    </div>

    <div className="flex justify-between mt-12 md:px-4 px-0">
      <p className="font-poppins font-bold flex flex-col items-center text-3xl text-white"><span className="text-2xl">Dividend</span><span><CountUp end={Number(10)} duration={2} />%</span></p>
      <p className="font-poppins font-bold flex flex-col items-center text-3xl text-white"><span className="text-2xl">Users</span><span><CountUp end={Number(data.totalUsers)} duration={2} />+</span></p>
      <p className="font-poppins font-bold flex flex-col items-center text-3xl text-white"><span className="text-2xl">Rating</span><span><CountUp end={4.9} duration={2} decimals={1} />+</span></p>
    </div>

    <div className="border border-gray-800 mt-12 w-full"></div>

    <div className="w-full mt-10 md:px-4 flex flex-col md:flex-row justify-center items-center">
      <img src='features.png' className='h-[380px] mt-8 mr-0 md:mr-40' />
      <div className="md:w-1/2 w-full">
        <p className="text-gradient mb-3 text-center text-2xl font-bold">Features</p>
        <div className="flex flex-row bg-purple-600 bg-opacity-10 rounded-xl p-4">
          <EmojiEventsIcon fontSize="large" color="inherit" />
          <div className="flex flex-col ml-4">
            <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
              Rewards
            </h4>
            <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">
            Our decentralized platform offers a variety of rewards for users who contribute to our ecosystem.
            </p>
          </div>
        </div>
        
        <div className="flex mt-4 flex-row bg-purple-600 bg-opacity-10 rounded-xl p-4">
          <VpnLockIcon fontSize="large" color="inherit" />
          <div className="flex flex-col ml-4">
            <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
              100% Secure
            </h4>
            <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">
            Our platform is built on smart contracts, providing a high level of security for all users.
            </p>
          </div>
        </div>
        
        <div className="flex mt-4 flex-row bg-purple-600 bg-opacity-10 rounded-xl p-4">
          <VerifiedIcon fontSize="large" color="inherit" />
          <div className="flex flex-col ml-4">
            <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
              Smart Contract
            </h4>
            <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">
            Smart contracts are self-executing digital contracts that use blockchain technology.
            </p>
          </div>
        </div>
        
        <div className="flex mt-4 flex-row bg-purple-600 bg-opacity-10 rounded-xl p-4">
          <BlockIcon fontSize="large" color="inherit" />
          <div className="flex flex-col ml-4">
            <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
              No Admin
            </h4>
            <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">  
            Smart contracts operate without the need for admin, as they are self-executing.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="border border-gray-800 mt-12 w-full"></div>

    <div className="w-full mt-2 md:px-4 flex flex-col-reverse md:flex-row justify-center items-center">
      <div className="md:w-1/2 w-full">
        <p className="text-yellow-400 mb-3 text-3xl font-bold">Binance Smart Chain</p>
        <p>Binance blockchain, also known as the Binance Smart Chain (BSC), is a blockchain platform developed by Binance, one of the largest cryptocurrency exchanges in the world. It was launched in September 2020 and is designed to provide a high-performance, low-cost blockchain network that can support decentralized applications (dApps) and smart contracts. This makes it more affordable for users to participate in decentralized finance (DeFi) applications and other blockchain based activities</p>
      </div>
      <img src='binance.png' className='h-[400px] mt-8 ml-0 md:ml-40' />
    </div>

    <div className="border border-gray-800 md:mt-2 mt-12 w-full"></div>

    <div className="w-full mt-8 md:px-4 flex flex-col md:flex-row justify-center items-center">
      <img src='robot.png' className='h-[350px] mt-8 mr-0 md:mr-40' />
      <div className="md:w-1/2 w-full">
        <p className="text-gradient mb-3 text-3xl font-bold">Robot Trading</p>
        <p>The platform utilizes robot trading in both the forex and crypto markets to generate additional funds for the community, increasing the overall return on investment. Robot trading, also known as algorithmic trading or automated trading, can be used in both forex and crypto trading. In forex and crypto trading, robot trading involves using computer programs or software algorithms to execute trades automatically based on predetermined rules or strategies.</p>
      </div>
    </div>

    <div className="border border-gray-800 mt-12 w-full"></div>

    <div className="w-full p-2 mt-8">
      <p className="text-2xl font-semibold text-yellow-400 text-center">Get started with BGN</p>
      <div className="flex md:flex-row flex-col justify-between w-full">
      <div className="flex w-full md:w-1/3 mt-6 flex-col items-center bg-purple-600 bg-opacity-10 rounded-xl p-4">
          <img className="h-10 mb-2" src="TWT.png" />
          <div className="flex text-center flex-col ml-4">
            <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
              Web3 Wallet
            </h4>
            <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">
            Any Web3 Wallet required Ex- turst wallet, metamask token Pocket
            </p>
        </div>
      </div>
      <div className="flex w-full md:w-1/3 ml-0 md:ml-4 mt-6 flex-col items-center bg-purple-600 bg-opacity-10 rounded-xl p-4">
          <img className="h-10 mb-2" src="bnb.png" />
          <div className="flex text-center flex-col ml-4">
            <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
              BNB Coin
            </h4>
            <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">
              BNB Balnce is required to Pay transaction Fees on Blockchain.
            </p>
        </div>
      </div>
      <div className="flex w-full md:w-1/3 ml-0 md:ml-4 mt-6 flex-col items-center bg-purple-600 bg-opacity-10 rounded-xl p-4">
          <img className="h-10 mb-2" src="usdt.png" />
          <div className="flex text-center flex-col ml-4">
            <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
              USDT Token
            </h4>
            <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">
              USDT is a stable coin, equvivalent to USD. It will be used as a fuel for project.
            </p>
        </div>
      </div>
      </div>
    </div>

    <div className="border border-gray-800 mt-12 w-full"></div>

    {/* Rewards */}
    <div className="w-full mt-8 md:px-4 flex flex-col-reverse md:flex-row justify-center items-center">
      <div className="md:w-1/2 w-full">
        <p className="text-gradient mb-3 text-center text-2xl font-bold">Types of Income</p>
        <div className="flex flex-row bg-purple-600 bg-opacity-10 rounded-xl p-4">
          <PixIcon fontSize="large" color="inherit" />
          <div className="flex flex-col ml-4">
            <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
              Dividend Income
            </h4>
            <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">
              Get 10% of your investment as dividend income every month
            </p>
          </div>
        </div>
        
        <div className="flex mt-4 flex-row bg-purple-600 bg-opacity-10 rounded-xl p-4">
          <LayersIcon fontSize="large" color="inherit" />
          <div className="flex flex-col ml-4">
            <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
              Direct & Layer Income
            </h4>
            <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">
              Get 10% of direct income & 10% of layer income on dividend upto 10 Layers.
            </p>
          </div>
        </div>
        
        <div className="flex mt-4 flex-row bg-purple-600 bg-opacity-10 rounded-xl p-4">
          <MilitaryTechIcon fontSize="large" color="inherit" />
          <div className="flex flex-col ml-4">
            <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
              Reward Bonanza
            </h4>
            <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">
              Every User will get Instant Reward on completing Certain business tragets.
            </p>
          </div>
        </div>
        
        <div className="flex mt-4 flex-row bg-purple-600 bg-opacity-10 rounded-xl p-4">
          <LeaderboardIcon fontSize="large" color="inherit" />
          <div className="flex flex-col ml-4">
            <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
              Royalty Income
            </h4>
            <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">  
            1% of total global Turnover will be distributed as royalty income.
            </p>
          </div>
        </div>
      </div>
      <img src='income.png' className='h-[380px] mt-8 ml-0 md:ml-40' />
    </div>

    <div className="border border-gray-800 mt-12 w-full"></div>

    {/* Team Bonanza */}
    <div className="w-full p-2 mt-8">
      <p className="text-2xl font-semibold text-yellow-400 text-center">Domestic Tours</p>
      <div className="flex md:flex-row flex-col justify-between w-full">
      <div className="flex w-full md:w-1/2 mt-6 flex-col items-center bg-purple-600 bg-opacity-10 rounded-xl p-2">
          <img className="h-full" src="goa.png" />
      </div>
      <div className="flex w-full md:w-1/2 ml-0 md:ml-4 mt-6 flex-col items-center bg-purple-600 bg-opacity-10 rounded-xl p-4">
          <img className="h-full" src="digha.png" />
      </div>
      </div>
    </div>


    <footer className="mb-8 text-gradient text-center mt-20 font-semibold text-2xl">
      Join the Revolution Now
    </footer>
  </div>
  )
};

export default Homepage;